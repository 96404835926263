import { Inject, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserData } from '../models';
import { HttpClient } from '@angular/common/http';
import { ENV_CONFIG, EnvironmentConfig } from '../environment.config';

@Injectable({
  providedIn: 'root',
})
export class ManageService extends ApiService {
  //Subjects: anything that subscribes will get the latest version of the subject

  //Subject Observables: allows components to subscribe to changes of the subject.

  private dataUrl: {};

  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) protected config: EnvironmentConfig
  ) {
    super(http, config);

    console.log(config);

    // this.dataUrl = { user: `${this.config.environment.apiUrl}/user`, };
  }

  // todo: place general get/post methods here
}
