import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ApplicationConfig, enableProdMode } from '@angular/core';
import { EnvironmentConfig } from './app/environment.config';

if (environment.production) {
  enableProdMode();
}

// fetch(`${document.location.origin}/proxy`)
//   .then((response) => {
//     if (!response.ok)
//       throw new Error(
//         `Failed to connect to proxy server at ${document.location.origin}/proxy`
//       );
//     return response.json();
//   })
//   .then((env) => {
//     // Verify env data is complete
//     const errors: string[] = [];
//     if (!env['clientId']) errors.push('Error: Missing property "clientId" from environment configuration');
//     if (!env['authority']) errors.push('Error: Missing property "authority" from environment configuration');
//     if (!env['apiUrl']) errors.push('Error: Missing property "apiUrl" from environment configuration');
//     if (!env['resourceScope']) errors.push('Error: Missing property "resourceScope" from environment configuration');
//     if (!env['redirectUrl']) errors.push('Error: Missing property "redirectUrl" from environment configuration');
//     if (errors.length) {
//       errors.forEach(error => document.writeln(`<div>${error}</div>`));
//       throw new Error(`Data received from proxy server: ${JSON.stringify(env)}`);
//     }

//     return env;
//   })
//   // build the angular app
//   .then(env => platformBrowserDynamic([{ provide: 'ENV', useValue: env }]).bootstrapModule(AppModule))
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);
    document.write(`<div>${err}</div>`);
    document.write(`<br/>Stack trace:`);
    document.write(`<div>${err.stack}</div>`);
    document.close();
  });
