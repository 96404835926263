import { Component } from "@angular/core";
import { BaseComponent } from "../../base/base.component";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss']
})
export class OrderComponent extends BaseComponent {
    constructor(protected override titleService: Title) {
        super(titleService);
    }

    override ngOnInit(): void {
        super.ngOnInit();
    }
}