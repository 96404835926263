import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnDestroy, OnInit {
  protected ngUnsubscribe: Subject<any> = new Subject();
  protected log: LogConfig = {
    none: false,
    all: false,
    checked: false,
    changes: false,
    initDestroy: false,
    viewContent: false,
  };

  get name(): string {
    const heading = this['heading'] ? ` (${this['heading']})` : '';
    return `${this.constructor.name}${heading}`;
  }

  private evalLog = (properties: string[] = null): boolean => {
    return (
      !this.evalLogProp('none') &&
      (this.evalLogProp('all') || properties.every((p) => this.evalLogProp(p)))
    );
  };
  private evalLogProp = (property: string): boolean => {
    return environment.log[property] || this.log[property];
  };

  constructor(protected titleService: Title) {}

  ngOnInit(): void {
    if (this.evalLog(['initDestroy'])) console.log(this.name, 'ngOnInit');
  }

  ngOnDestroy(): void {
    if (this.evalLog(['initDestroy'])) console.log(this.name, 'ngOnDestroy');

    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}

export interface LogConfig {
  none: boolean;
  all: boolean;
  checked: boolean;
  changes: boolean;
  initDestroy: boolean;
  viewContent: boolean;
}
