import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
import { PermissionRoutesEnum } from './models';
import { OrderComponent } from './operations/order/order.component';

const guards = environment.enableAuth ? [] : [];
export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'operations',
    children: [
      {
        path: 'order',
        component: OrderComponent,
        data: { claims: [PermissionRoutesEnum.Operations_Order] },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
