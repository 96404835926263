import { User } from "@auth0/auth0-angular";

export enum PermissionRoutesEnum {
  //Order Tab
  Order_ViewOrder = 'order/view-order',
  //Operations
  Operations_Order = 'operations/order'
}

// #region Core Data Models
export class EntityData {
  id?: number;
  rowVersion?: Uint8Array;

  static equalRowVersion(a: Uint8Array, b: Uint8Array) {
      return a?.length === b?.length && a.every((val, i) => val === b[i]);
  }
}

// #region Authorization & User Models
export class UserData extends EntityData {
  username: string;
  firstName: string;
  lastName: string;
  customerName: string;
  customerId: number;
}

export class AccountData {
  auth0User: User;
  mccUser: UserData;
  isAuthenticated: boolean;
}
