<div *ngIf="isVerified">
    <h2>What would you like to do?</h2>
    <div>
        <button (click)="fetchUser()">Fetch own user information</button>
        <button (click)="fetchUsers()">Fetch users</button>
        <ul>@for (user of users; track $index) {
            <li>user: {{ user.firstName }} {{ user.lastName }}</li>
            }
        </ul>
    </div>
</div>