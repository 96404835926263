import { Inject, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserData } from '../models';
import { HttpClient } from '@angular/common/http';
import { ENV_CONFIG, EnvironmentConfig } from '../environment.config';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ApiService {
  //Subjects: anything that subscribes will get the latest version of the subject
  private usersSubject: BehaviorSubject<UserData[]> = new BehaviorSubject<
    UserData[]
  >(null);

  //Subject Observables: allows components to subscribe to changes of the subject.
  public get users(): Observable<UserData[]> {
    return this.usersSubject;
  }

  private dataUrl: {
    user: string;
  };

  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) protected config: EnvironmentConfig
  ) {
    super(http, config);

    /* DEBUG */
    console.log(config);

    this.dataUrl = {
      user: `${this.config.environment.apiUrl}/user`,
    };
  }

  // API calls
  public getUsers() {
    console.log(this.dataUrl.user);
    this.getDataRecords<UserData>(
      `${this.dataUrl.user}/GetUsers`,
      this.usersSubject,
      UserData
    );
  }

  public getUserByUsername(username: string = null) {
    console.log('user to fetch: ', username);
    const r = this.http.get<UserData>(
      `${this.dataUrl.user}/GetUserByUsername?username=${username}`
    );
    return r;
  }
}
