import { Inject, Injectable } from '@angular/core';
import { EntityData } from './models';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { Data } from '@angular/router';
import { ENV_CONFIG, EnvironmentConfig } from './environment.config';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(protected http: HttpClient, @Inject(ENV_CONFIG) protected config: EnvironmentConfig) {}

  protected updateDataRecord<T>(url: string, model: EntityData) {
    const params = new HttpParams().set('id', String(model.id));
    const head = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url, model, {
      params: params,
      headers: head,
    });
  }

  protected updateDataRecords<T>(url: string, model: EntityData[]) {
    const head = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url, model, {
      headers: head,
    });
  }

  protected getDataRecord<DataType>(
    url: string,
    subject: BehaviorSubject<DataType>,
    mapToType: new (data) => DataType = null,
    errorHandler: () => void = function () {}
  ): Observable<DataType> {
    return this.getAndMapDataRecord<DataType, DataType>(
      url,
      subject,
      (d) => Object.assign(new mapToType(d), d),
      errorHandler
    );
  }

  protected getAndMapDataRecord<DataType, ResultType>(
    url: string,
    subject: BehaviorSubject<ResultType>,
    mapFunction: (data) => ResultType = null,
    errorHandler: () => void = function () {}
  ) {
    if (subject) {
      this.http.get<DataType>(url).subscribe((result) => {
        // Explicitly map our result as a class object so functions work.
        const record = mapFunction(result);

        // Set our subject with our new collection.
        subject.next(record);
      }, errorHandler);
    } else {
      return this.http.get<DataType>(url).pipe(
        map((result) => {
          // Explicitly map our result as a class object so functions work.
          const record = mapFunction(result);
          return record;
        }, errorHandler)
      );
    }
  }

  protected getDataRecords<DataType>(
    url: string,
    subject: BehaviorSubject<DataType[]>,
    mapToType: new (data) => Data = null,
    errorHandler: () => void = function () {}
  ): Observable<DataType[]> {
    return this.getAndMapDataRecords<DataType, DataType>(
      url,
      subject,
      (d) => Object.assign(new mapToType(d), d),
      errorHandler
    );
  }

  protected getAndMapDataRecords<DataType, ResultType>(
    url: string,
    subject: BehaviorSubject<ResultType[]>,
    mapFunction: (data) => ResultType = null,
    errorHandler: () => void = function () {}
  ): Observable<ResultType[]> {
    if (subject) {
      this.http.get<DataType[]>(url).subscribe((result) => {
        // Explicitly map our result as a class object so functions work.
        const records = result.map((d) => mapFunction(d));

        // Set our subject with our new collection.
        subject.next(records);
      }, errorHandler);
    } else {
      return this.http.get<DataType[]>(url).pipe(
        map((result) => {
          // Explicitly map our result as a class object so functions work.
          const records = result.map((d) => mapFunction(d));
          return records;
        }, errorHandler)
      );
    }
  }

  protected deleteDataRecord<DataType extends EntityData>(
    url: string,
    subject: BehaviorSubject<DataType[]>,
    id: number
  ) {
    const params = new HttpParams().set('id', String(id));

    return this.http
      .delete(url, { params })
      .pipe(
        tap(() =>
          subject.next(subject.value?.filter((entity) => entity?.id !== id))
        )
      );
  }
}
