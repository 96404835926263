<nav class="main-navigation">
  <ul>
    <a mat-button (click)="(false)" routerLink="/">{{ appName }}</a>

    <li>
      <a routerLinkActive="active" routerLink="/operations/order">Order</a>
    </li>
  </ul>
  <div>
    <button *ngIf="!isAuthenticated" (click)="login()">Log In</button>
    <button *ngIf="isAuthenticated" (click)="logout()">Log Out</button>
    <button *ngIf="isAuthenticated" (click)="logUserData()">Log User Data</button>
  </div>
</nav>
