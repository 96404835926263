export const environment = {
    production: true,
    enableAuth: true,
    navText: 'ZendiConnect (Dev)',
    version: '1.00',
    clientId: 'f1BzYG1OhtKSP2ezV7OiykQzv0bDOyaf',
    authority: 'zendi-connect.us.auth0.com',
    authorityId: 'zendi-connect-api-1',
    apiUrl: 'https://zendi-connect-dev-api.azurewebsites.net/v1', // App Service Default Domain
    resourceScope: 'api://cb3f5e55-c0ef-4028-8d1e-5de94fc99c0d/access_as_user', // API Client Id
    redirectUrl: 'https://zendi-connect-dev-web.azurewebsites.net', // https://alpha.zendi.connect.murphyinc.org
    loginScopes: ['user.read', 'openid', 'profile'],
    log: {
      none: true,
      all: false,
      checked: false,
      changes: false,
      initDestroy: false,
      viewContent: false,
    },
  };
  