import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthorizationService } from './authorization/authorization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ZendiConnect';
  private readonly destroying$ = new Subject<void>();
  isIframe: boolean = false;

  public constructor(authService: AuthorizationService) {
  }

  ngOnInit(): void {
    if (typeof window !== 'undefined')
      this.isIframe = window !== window.parent && !window.opener;
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  // 

  public checkAccount() {
    // if (!this.loggedIn) this.authService.login();
  }
}
