import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Title } from '@angular/platform-browser';
import { AuthorizationService } from '../authorization/authorization.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent {
  appName: string = '';

  protected get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

  constructor(protected override titleService: Title, private authService: AuthorizationService) {
    super(titleService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.appName = 'zendi-connect-test';
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  logUserData() {
    console.log(this.authService.verifiedAccountData);
  }
}
